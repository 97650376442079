var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"relatorios-historico","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-export","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Exportação de Dados :: "),(true)?[_vm._v(" Histórico ")]:_c('v-progress-circular',{attrs:{"indeterminate":"","size":"18","color":"primary"}})],2)]},proxy:true}])},[_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"to":"/dados/exportacoes-indger/edit","id":"btn-novo-relatorio","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova Exportação ")],1),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-relatorios-historico",staticStyle:{"max-width":"250px"},attrs:{"id":"relatorios-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"relatorios-historico-table",attrs:{"headers":[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Título',
                value: 'title'
              },
              {
                text: 'Base',
                value: 'base'
              },
              {
                text: ' Criado por',
                value: 'usuario'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                text: 'Executado em',
                value: 'executado_em'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ],"items":_vm.dados,"headerProps":{
              sortByText: 'Ordenar Por'
            },"footer-props":{
              itemsPerPageText: 'Itens por Página'
            },"no-data-text":"Nenhum relatório encontrado","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.base",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translationBase[item.base])+" ")]}},{key:"item.usuario",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.usuario)+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.executado_em",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.executado_em))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:item.status != 'SUCESSO' ? 'cursor-block' : '',attrs:{"id":"btn-download-arquivo-resultado","disabled":item.status != 'SUCESSO',"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.downloadExportacao(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"px-1 ml-n1",class:item.status == 'EXECUTANDO' ? 'cursor-block' : '',attrs:{"id":"btn-edit-relatorio","color":"gray","min-width":"0","fab":"","icon":"","x-small":"","disabled":item.status == 'EXECUTANDO' || item.status == 'AGUARDANDO'},on:{"click":function($event){return _vm.$router.push(("edit/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),(item.status != 'EXECUTANDO')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"btn-mais-acoes","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(item.status != 'AGUARDANDO' && !item.disabled)?_c('v-list-item',{class:item.status == 'EXECUTANDO' ? 'cursor-block' : '',on:{"click":function($event){return _vm.executarExportacao(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(!item.disabled)?_c('v-list-item',{class:item.status == 'EXECUTANDO' ? 'cursor-block' : '',on:{"click":function($event){$event.stopPropagation();return _vm.openDialogExclusao(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Excluir ")],1)],1):_vm._e()],1)],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"480"},model:{value:(_vm.dialog_exclusao_exportacao),callback:function ($$v) {_vm.dialog_exclusao_exportacao=$$v},expression:"dialog_exclusao_exportacao"}},[_c('v-card',[_c('v-card-title',{staticClass:"display-2"},[_vm._v(" Deletar Exportação #"+_vm._s(_vm.relatorioExclusao.id)+" ")]),_c('v-card-text',[_c('p',{staticClass:"text-center"},[_vm._v("Tem certeza qe deseja deletar o relatório "),_c('b',[_vm._v(_vm._s(_vm.relatorioExclusao.title))]),_vm._v(" ?")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialog_exclusao_exportacao = false}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.deleteExportacao()}}},[_vm._v(" Excluir ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }