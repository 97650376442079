<template>
  <v-container id="relatorios-historico" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="primary" icon="mdi-export" inline class="px-5 py-4 ml-0">
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Exportação de Dados ::
              <template v-if="true">
                Histórico
              </template>
              <v-progress-circular v-else indeterminate size="18" color="primary" />
            </div>
          </template>

          <v-btn to="/dados/exportacoes-indger/edit" id="btn-novo-relatorio" color="success" dark elevation="1"
            class="mt-5" style="float:left" relative text medium>
            <v-icon left size="30px">
              mdi-plus-circle
            </v-icon>
            Nova Exportação
          </v-btn>

          <v-text-field id="relatorios-historico-search" append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-relatorios-historico" label="Procurar" hide-details single-line
            style="max-width: 250px;" />

          <!-- <v-alert dense outlined type="info" color="blue" class="mb-0 py-1 px-1" v-if="true">
            asdfafsddsfa
          </v-alert> -->

          <v-divider class="mt-10" />

          <v-data-table class="relatorios-historico-table" :headers="[
                {
                  text: '#',
                  value: 'id'
                },
                {
                  text: 'Título',
                  value: 'title'
                },
                {
                  text: 'Base',
                  value: 'base'
                },
                {
                  text: ' Criado por',
                  value: 'usuario'
                },
                {
                  text: 'Status',
                  value: 'status'
                },
                {
                  text: 'Executado em',
                  value: 'executado_em'
                },
                {
                  sortable: false,
                  text: 'Ações',
                  value: 'actions',
                  class: 'pl-4'
                }
              ]" :items="dados" :headerProps="{
                sortByText: 'Ordenar Por'
              }" :footer-props="{
                itemsPerPageText: 'Itens por Página'
              }" no-data-text="Nenhum relatório encontrado" no-results-text="Nenhum registro correspondente encontrado"
            :mobile-breakpoint="800" 
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            >

            <template v-slot:[`item.id`]="{ item }">
              {{ item.id }}
            </template>
            <template v-slot:[`item.base`]="{ item }">
              {{ translationBase[item.base] }}
            </template>

            <template v-slot:[`item.usuario`]="{ item }">
              {{ item.usuario }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>

            <template v-slot:[`item.executado_em`]="{ item }">
              {{ item.executado_em | parseToDateTimeBR }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn @click="downloadExportacao(item.id)" id="btn-download-arquivo-resultado"
                :class="item.status != 'SUCESSO' ? 'cursor-block' : ''" :disabled="item.status != 'SUCESSO'" color="gray"
                min-width="0" class="px-1" fab icon x-small>
                <v-icon small>
                  mdi-download
                </v-icon>
              </v-btn> <v-btn class="px-1 ml-n1" :class="item.status == 'EXECUTANDO' ? 'cursor-block' : ''"
                id="btn-edit-relatorio" color="gray" min-width="0" fab icon x-small
                :disabled="item.status == 'EXECUTANDO' || item.status == 'AGUARDANDO'" @click="$router.push(`edit/${item.id}`)">
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-menu offset-y v-if="item.status != 'EXECUTANDO'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn id="btn-mais-acoes" color="white" class="ml-1" height="22px" width="22px" fab x-small
                    elevation="1" v-bind="attrs" v-on="on">
                    <v-icon small>
                      mdi mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="item.status != 'AGUARDANDO' && !item.disabled" :class="item.status == 'EXECUTANDO' ? 'cursor-block' : ''"
                    @click="executarExportacao(item)">
                    <v-list-item-title>
                      <v-icon small>
                        mdi-play
                      </v-icon>
                      Executar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!item.disabled" :class="item.status == 'EXECUTANDO' ? 'cursor-block' : ''"
                    @click.stop="openDialogExclusao(item)">
                    <v-list-item-title>
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                      Excluir
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>


            </template>


          </v-data-table>
        </base-material-card>


      </v-col>
    </v-row>
    <v-dialog max-width="480" v-model="dialog_exclusao_exportacao">
      <v-card>
        <v-card-title class="display-2">
          Deletar Exportação #{{relatorioExclusao.id  }}
        </v-card-title>
        <v-card-text>
          <p class="text-center">Tem certeza qe deseja deletar o relatório <b>{{ relatorioExclusao.title }}</b> ?</p>

        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialog_exclusao_exportacao = false">
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn color="red" text @click="deleteExportacao()">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import AuthService from '@/services/AuthService';
import ExportacaoDadosService from '@/services/ExportacaoDadosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [
    refreshDataMixins,
    tourMixins
  ],
  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
  },
  data() {
    return {
      slug: null,
      search: null,
      relatorio: {
        nome: null
      },
      dialog_exclusao_exportacao : false,
      translationBase: {
        'comercial_v2': 'Comercial V2',
        'servicos_v2': 'Serviços V2',
        'reclamacao': 'Reclamações',
        'tec_al': 'Alimentadores',
        'tec_sub': 'Subestações',
        'tec_ld': 'Linhas Distribuições',
        'indger_compilado': 'INDGER Compilado'
      },
      relatorios: [],
      downloadProgressData: {},
      dialogDownloadSelector: false,
      dialogDownloadSelectorData: {},
      dialogDelete: false,
      dialogDeleteData: {
        id: undefined,
        titulo: undefined
      },


      tourFuncCalled: false,
      loading: false,
      dados: [],
      relatorioExclusao: {}
    }
  },
  methods: {
    openDialogExclusao(item){
      this.dialog_exclusao_exportacao = true;
      this.relatorioExclusao = item;

    },
    getData() {
      this.loading = true;
      ExportacaoDadosService.getData().then((response) => {
        this.dados = response.data.data
      }).finally(() => this.loading = false);
    },
    deleteExportacao() {
    this.loading = true;
      this.dialog_exclusao_exportacao = false;
      let id = this.relatorioExclusao.id;
      ExportacaoDadosService.delete(id).then((response) => {
        this.$toast.success(`Exportação excluída com sucesso.`, '', {
          position: 'topRight'
        });
      }).catch((error) => {
        this.$toast.error(error.error, '', {
          position: 'topRight'
        });
      }).finally(()=> this.getData());
    },

    downloadExportacao(id) {
      this.loading = true;
      ExportacaoDadosService.getLinkDownload(id)
        .then(res => {
          window.open(res.data.link, '_blank').focus();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('Erro no download do arquivo.', '', {
            position: 'topRight',
          });
        }).finally(()=> this.loading = false)
    },
    executarExportacao(item) {
      item.disabled = true;
      this.loading = true;
      ExportacaoDadosService.executarExportacao(item.id)
        .then(res => {

          this.$toast.success(`A exportação será processada novamente.`, '', {
            position: 'topRight'
          });
          item.disabled = false;

        })
        .catch((error) => {
          this.$toast.error('Erro no processamento da Exportacao.', '', {
            position: 'topRight',
          });
        }).finally(()=> this.getData());
      item.disabled = false;
    },
  },
  created() {
    this.getData();
    setInterval(() => {
      this.getData()
    }, 10000);
  },
}

</script>

<style>
@media screen and (min-width: 800px) {
  .relatorios-historico-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .relatorios-historico-table table tr td:nth-child(6) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-relatorios-historico {
    width: 235.86px !important;
  }
}
</style>